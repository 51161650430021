import React from "react";
import { Button, Flex } from "@appsmith/wds";

import styles from "./styles.module.css";
import type { PromptSuggestion } from "./types/promptSuggestion";

interface PromptSuggestionsProps {
  suggestions: PromptSuggestion[];
  onApplyAssistantSuggestion?: (suggestion: string) => void;
}

export const ChatPromptSuggestions = (props: PromptSuggestionsProps) => {
  const { onApplyAssistantSuggestion, suggestions } = props;

  if (suggestions.length === 0) return null;

  return (
    <Flex
      className={styles.suggestions}
      data-testid="t--ai-chat-prompt-suggestion-list"
      gap="spacing-2"
      wrap="wrap"
    >
      {suggestions.filter(Boolean).map((suggestion) => (
        <Button
          className={styles.suggestionButton}
          color="neutral"
          key={suggestion.label}
          onPress={() => onApplyAssistantSuggestion?.(suggestion.value)}
          variant="outlined"
        >
          {suggestion.label}
        </Button>
      ))}
    </Flex>
  );
};
