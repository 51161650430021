import React from "react";

import { ChatCitationButton } from "../ChatCitationButton";
import type { MessageCitation } from "../types";

interface ChatWebPageCitationItemProps {
  citation: MessageCitation;
  onOpen?: (citation: MessageCitation) => void;
  selectedCitationId?: string | null;
}

export const ChatWebPageCitationItem = React.memo(
  (props: ChatWebPageCitationItemProps) => {
    const { citation, onOpen, selectedCitationId } = props;
    const isSelected = selectedCitationId === citation.id;

    return (
      <ChatCitationButton
        isSelected={isSelected}
        onPress={() => onOpen?.(citation)}
      />
    );
  },
);
