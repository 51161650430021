import { useState, useEffect } from "react";
import type { CitationDetails } from "../types";

const documentCache = new Map<string, Blob | string>();

export const useFetchDocument = (
  url?: string,
  type?: CitationDetails["integrationType"],
  id?: string | null,
) => {
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [document, setDocument] = useState<Blob | string | null>(null);

  useEffect(
    // this is required to clear the document when the citation id changes
    // otherwise our document viewer can try to render an old document
    // For e.g text document viewer can try to render a pdf document which will fail
    function handleDocumentIdChange() {
      setDocument(null);
    },
    [id],
  );

  async function fetchData() {
    if (!url) return;

    const name = url.split("?")[0] as string;
    const cachedDoc = documentCache.get(name);

    if (cachedDoc) {
      setDocument(cachedDoc);
      setHasError(false);

      return;
    }

    setIsLoading(true);

    const controller = new AbortController();

    try {
      const res = await fetch(url, { signal: controller.signal });

      let blob;

      if (type === "PDF") {
        blob = await res.blob();
      } else {
        blob = await res.text();
      }

      documentCache.set(name, blob);
      setDocument(blob);
      setHasError(false);
    } catch (error) {
      setHasError(true);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(
    function fetchDocument() {
      fetchData();
    },
    [url, type, id],
  );

  return { isLoading: isLoading, hasError, document, retry: fetchData };
};
