import React, { useState, useRef, useEffect } from "react";
import { Flex } from "@appsmith/wds";
import ReadOnlyEditor from "components/editorComponents/ReadOnlyEditor";
import { clsx } from "clsx";
import { getTypographyClassName } from "@appsmith/wds-theming";
import { IconButton, Text } from "@appsmith/wds";
import styles from "./styles.module.css";
import type { ToolCall } from "../types/toolCall";
import { codeMirrorFixedHeightCalculator } from "./utils";

interface CodeViewerProps {
  toolCall: ToolCall;
}

export const CodeViewer = (props: CodeViewerProps) => {
  const { toolCall } = props;

  const CodeViewRef = useRef<HTMLDivElement>(null);

  const [codeViewHeight, setCodeViewHeight] = useState<number>(0);

  useEffect(function handleDynamicHeight() {
    if (CodeViewRef.current) {
      const height = CodeViewRef.current.clientHeight;

      setCodeViewHeight(height);
    }
  }, []);

  const [argsHeight, outputHeight] =
    codeMirrorFixedHeightCalculator(codeViewHeight);

  if (
    toolCall.executionStatus !== "success" &&
    toolCall.executionStatus !== "error"
  ) {
    return null;
  }

  const toolCallOutput = "error" in toolCall ? toolCall.error : toolCall.output;
  const args = toolCall.entity.arguments;

  return (
    <Flex
      alignItems="start"
      className={styles.toolCallResponseViewer}
      direction="column"
      gap="spacing-4"
      isInner
      padding="spacing-4"
      ref={CodeViewRef}
    >
      <div className={styles.toolCallResponseContainer}>
        <div className={clsx(styles.toolCallResponseViewerHeader)}>
          <Text color="neutral-subtle" size="caption">
            Arguments
          </Text>
          <IconButton
            color="neutral"
            icon={"copy"}
            onPress={() => {
              navigator.clipboard.writeText(args);
            }}
            size="small"
            variant="ghost"
          />
        </div>
        <ReadOnlyEditor
          className={getTypographyClassName("caption")}
          folding
          height={argsHeight}
          input={{
            value: JSON.stringify(JSON.parse(args), null, 2),
          }}
        />
      </div>
      <div className={styles.toolCallResponseContainer}>
        <div className={clsx(styles.toolCallResponseViewerHeader)}>
          <Text color="neutral-subtle" size="caption">
            Output
          </Text>
          <IconButton
            color="neutral"
            icon={"copy"}
            onPress={() => {
              navigator.clipboard.writeText(toolCallOutput);
            }}
            size="small"
            variant="ghost"
          />
        </div>
        <ReadOnlyEditor
          className={getTypographyClassName("caption")}
          folding
          height={outputHeight}
          input={{
            value: toolCallOutput,
          }}
        />
      </div>
    </Flex>
  );
};
