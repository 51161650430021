import React from "react";
import { Markdown } from "@appsmith/wds";

import { processNotionMarkdown } from "./helpers";
import { ChatSidebarError } from "../../ChatSidebarError";
import type { MarkdownDocumentViewerProps } from "./types";
import styles from "../TextDocumentViewer/styles.module.css";

export const MarkdownDocumentViewer = (props: MarkdownDocumentViewerProps) => {
  const { document, hasError, ...rest } = props;
  const contentNodeRef = React.useRef<HTMLDivElement>(null);

  const renderContent = () => {
    if (hasError) {
      return <ChatSidebarError errorMessage="Can't load the file..." />;
    }

    if (document) {
      return (
        <Markdown ref={contentNodeRef}>
          {processNotionMarkdown(document)}
        </Markdown>
      );
    }

    return null;
  };

  return (
    <div className={styles.documentViewer} {...rest}>
      <div className={styles.content}>{renderContent()}</div>
    </div>
  );
};
