import React from "react";
import styles from "./styles.module.css";

export const ChatResponseLoadingIndicator = () => {
  return (
    <div className={styles.dotLoader}>
      <div className={styles.dot} />
      <div className={styles.dot} />
      <div className={styles.dot} />
    </div>
  );
};
