export const codeMirrorFixedHeightCalculator = (
  totalHeight: number,
): [string, string] => {
  // The padding and gaps
  const fixedHeights = `calc(var(--inner-spacing-4) * 4 + var(--inner-spacing-2) * 6)`;
  const availableHeight = `calc(${totalHeight}px - ${fixedHeights})`;
  // Arguments and output are 15% and 85% of the total available height respectively
  const argsRatio = 0.15;
  const outputRatio = 1 - argsRatio;
  // The height of the arguments and output are calculated using the available height
  const argsHeight = `calc(${availableHeight} * ${argsRatio} - var(--inner-spacing-2))`;
  const outputHeight = `calc(${availableHeight} * ${outputRatio} - var(--inner-spacing-2))`;

  return [argsHeight, outputHeight];
};
