import React, { useCallback } from "react";
import { ChatCitationButton } from "../ChatCitationButton";
import type { NotionMessageCitation } from "../types";

interface ChatNotionPageCitationItemProps {
  citation: NotionMessageCitation;
}

export const ChatNotionPageCitationItem = React.memo(
  ({ citation }: ChatNotionPageCitationItemProps) => {
    const handleButtonPress = useCallback(() => {
      window.open(citation.url, "_blank", "noopener,noreferrer");
    }, [citation.url]);

    return <ChatCitationButton onPress={handleButtonPress} />;
  },
);
