import React from "react";
import type { VisualizationElements } from "entities/Action";
import { Result } from "PluginActionEditor/components/PluginActionResponse/components/Visualization/components/Result";
import { type ToolCall } from "../types/toolCall";
import styles from "./styles.module.css";
import { CodeViewer } from "./CodeViewer";

interface ToolCallResponseViewerProps {
  toolCall: ToolCall;
  visualizationEnabled?: boolean;
  visualizationElements: VisualizationElements | null;
}

export const ToolCallResponseViewer = ({
  toolCall,
  visualizationElements,
  visualizationEnabled = false,
}: ToolCallResponseViewerProps) => {
  if (
    toolCall.executionStatus !== "success" &&
    toolCall.executionStatus !== "error"
  ) {
    return null;
  }

  if (
    visualizationEnabled &&
    visualizationElements &&
    toolCall.executionStatus === "success"
  ) {
    return (
      <div className={styles.toolCallResponseViewer}>
        <Result data={toolCall.output} elements={visualizationElements} />
      </div>
    );
  }

  return <CodeViewer toolCall={toolCall} />;
};
