export const processNotionMarkdown = (markdown: string) => {
  let processedMarkdown = markdown.replace(/^title: (.*)$/m, "# $1");

  // convert the checkbox items to list items
  processedMarkdown = processedMarkdown
    .replace(/\[ \]/g, "- [ ]")
    .replace(/\[x\]/g, "- [x]");

  // fixing space before and after the ** ( for bold text)
  processedMarkdown = processedMarkdown
    .replace(/\*\*(\S.*?)\s+\*\*/g, "**$1** ")
    .replace(/\*\*(\S.*?)\*\*(\S)/g, "**$1** $2");

  return processedMarkdown;
};
