import { Button, type ButtonProps } from "@appsmith/wds";
import React from "react";

import styles from "./styles.module.css";

type ChatCitationButtonProps = {
  isSelected?: boolean;
} & ButtonProps;

export const ChatCitationButton = ({
  isSelected,
  ...props
}: ChatCitationButtonProps) => {
  return (
    <Button
      {...props}
      className={styles.citationButton}
      color={isSelected ? "accent" : "neutral"}
      icon="link"
      size="xSmall"
      variant="subtle"
    >
      <span className={styles.citationNumber} />
    </Button>
  );
};
